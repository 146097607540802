















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class MilliData extends Vue {
  @Prop() title?: string;
  @Prop() suffix?: string;
  @Prop() suffixMilli?: string;
  @Prop({ default: 0 }) value!: number;
  @Prop({ default: 0 }) precision!: number;

  milli = false;
}
