































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { Duration } from "luxon";
import { IYZXMessage } from "../YZX";

import MilliData from "./MilliData.vue";

@Component({
  components: {
    MilliData,
  },
})
export default class RealtimeCard extends Vue {
  @Prop() data?: IYZXMessage | null;
  @Prop() state?: "closed" | "opening" | "opened";

  get w(): number {
    return this.data ? this.data.a * this.data.v : 0;
  }

  formatTime({ value }: { value: number }): string {
    return Duration.fromMillis(value * 10).toFormat("hh:mm:ss.SSS");
  }
}
