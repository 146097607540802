





























import { Component, Vue } from "vue-property-decorator";
import RealtimeCard from "./components/RealtimeCard.vue";
import GraphCard from "./components/GraphCard.vue";

import YZX, { IYZXMessage } from "./YZX";

@Component({
  components: {
    RealtimeCard,
    GraphCard,
  },
})
export default class App extends Vue {
  meter?: YZX;
  state: "closed" | "opening" | "opened" = "closed";
  data: IYZXMessage | null = null;

  async open(): Promise<void> {
    try {
      this.state = "opening";

      this.meter = await YZX.open("wsa://default");
      this.meter.on("data", (data: IYZXMessage) => {
        this.data = data;
      });

      this.state = "opened";
    } catch (e) {
      this.state = "closed";
      console.warn("Failed opening device", e);
    }
  }

  async close(): Promise<void> {
    if (this.meter) {
      await this.meter.close();
      this.data = null;
      this.state = "closed";
    }
  }
}
